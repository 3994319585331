import { TableCell } from "@mui/material"
import { differenceInMinutes } from "date-fns";
import { Event } from "./Event";

const statusColor = (status) => {
    if (status === 'new') {
        return 'linear-gradient(138deg, rgb(152, 204, 236) 0%, rgb(111,180,222,1) 100%)'
    } else if (status === 'progress') {
        return 'linear-gradient(138deg, rgb(252, 224, 181) 0%, rgb(239, 206, 155) 100%)'
    }
    return '#e4f8e4'
};

const eventOpacity = (status) => {
    if (status === 'done') {
        return '40%'
    } else {
        return '100%'
    }
}

export const CalendarCell = ({ time, interval, loading, setLoadings }) => {
    const getRowSpan = () => {
        if (differenceInMinutes(loading.to_time, loading.from_time) / interval > 1) {
            return differenceInMinutes(loading.to_time, loading.from_time) / interval
        }
        return 1;
    }
    
    return (
        <TableCell rowSpan={getRowSpan(loading)} sx={{ minWidth: '360px', verticalAlign: 'top', background: statusColor(loading.status), 
            borderRadius: 3, position: 'relative', margin: 1, opacity: eventOpacity(loading.status) }}>
            <Event loading={loading} time={time} setLoadings={setLoadings}></Event>
        </TableCell>)
}