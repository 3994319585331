import { useState, useRef, useLayoutEffect, useEffect } from "react";
import { Typography, IconButton, ClickAwayListener, Grow, Card, Box, Popper, Chip, MenuItem, Select, Collapse, Button, Modal } from "@mui/material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import NotesIcon from '@mui/icons-material/Notes';
import ImageIcon from '@mui/icons-material/Image';
import { TimeChangeModal } from "./TimeChangeModal";
import axios from "axios";
import format from "date-fns/format";
import { ApiUrl } from "../helpers/ApiUrl";
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const statusColor = (status) => {
    if (status === 'new') {
        return '#46a4db'
    } else if (status === 'progress') {
        return '#FFC56E'
    }
    return '#92F092'
};

const collapse = (status) => {
    if (status === 'done') return false;
    return true;
};

const style = {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
};

const iconStyle = {
    color: 'secondary.main',
    marginRight: 0.5
};

const planStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '100%',
    maxHeight: '100%'
};

export const Event = ({ loading, time, setLoadings }) => {
    const [buttonOpen, setButtonOpen] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [showImage, setShowImage] = useState(false);
    const [width, setWidth] = useState(130);
    const buttonRef = useRef(null);
    const widthRef = useRef();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const handleOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    const getText = () => {
        if (showMore) {
            return t('show_less')
        };
        return t('show_more')
    };

    useLayoutEffect(() => {
        setWidth(widthRef.current.offsetWidth);
    }, [loading]);


    useEffect(() => {
        const isBase64UrlImage = async () => {
            let image = new Image()
            image.src = "data:image/png;base64," + loading.plan
            return await (new Promise((resolve)=>{
              image.onload = function () {
                if (image.height === 0 || image.width === 0) {
                  resolve(false);
                  return;
                }
                resolve(true)
              }
              image.onerror = () =>{
                resolve(false)
              }
            }))
          }
        isBase64UrlImage().then((response) => {
            setShowImage(response);
        }
        )
    }, [loading.plan]);

    const handleClick = (e) => {
        axios.post(ApiUrl() + '/api/loadingCalendar/' + loading.id + '/update_status', { status: e.target.value },
            {
                headers: {
                    "Content-Type": "application/json; Charset=UTF-8",
                    'Authorization': `Token ${JSON.parse(localStorage.getItem("api_key")).token}`
                },
            }).then((resp) => {
                setLoadings(prevState => {
                    const newState = prevState.map(obj => {
                        return (obj.id === loading.id) ? { ...obj, status: e.target.value } : obj;
                    });
                    return newState;
                });
                enqueueSnackbar(t('change', { item: t('status') }), { variant: "success" })
            }).catch((error) => {
                enqueueSnackbar(t('change_fail', { item: t('status') }), { variant: "error" })
            })
        setButtonOpen(false);
    };

    const handleToggle = () => {
        setButtonOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if ((buttonRef.current && buttonRef.current.contains(event.target))) {
            return;
        }
        setButtonOpen(false);
    };


    return (
        <>
            <IconButton onClick={handleToggle} sx={{ position: 'absolute', top: 10, right: 10, color: '#134E6F' }} ref={buttonRef}>
                <AccessTimeIcon></AccessTimeIcon>
            </IconButton>
            <Select
                value={loading.status}
                onChange={handleClick}
                sx={{ position: 'absolute', bottom: 10, right: 10, background: statusColor(loading.status) }}
                size="small"
                ref={widthRef}
            >
                <MenuItem value={'new'}>{t('new')}</MenuItem>
                <MenuItem value={'progress'}>{t('progress')}</MenuItem>
                <MenuItem value={'done'}>{t('done')}</MenuItem>
            </Select>
            {loading.plan && <IconButton onClick={handleOpen} sx={{ position: 'absolute', top: 10, right: 50, color: '#134E6F' }}><ImageIcon></ImageIcon></IconButton>}

            <Typography variant="h5" component="div" sx={{ width: `calc(100% - 40px)` }}>
                {loading.supplier} <Chip size="small" variant="outlined" sx={{ textTransform: "uppercase", fontSize: "0.7rem", border: "1px solid black;" }} label={t(loading.category)}></Chip>
            </Typography>
            <Collapse in={!collapse(loading.status)}>
                <Button color="secondary" size="small" onClick={() => setShowMore(oldValue => !oldValue)}>{getText()}</Button>
            </Collapse>
            <Collapse in={collapse(loading.status) || showMore}>
                <Typography sx={{ fontWeight: 500, fontSize: '1.2rem' }}>{loading.reference}</Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {format(time, 'HH:mm')}- {format(loading.to_time, 'HH:mm')}
                </Typography>
                <Box sx={{ width: `calc(100% - ${width}px)` }}>
                    {loading.transport_company &&
                        <Typography sx={style}>
                            <LocalShippingIcon sx={iconStyle}></LocalShippingIcon>
                            {loading.transport_company}
                            {loading.registration_plate && <span style={{ marginLeft: 0.5, fontSize: '0.8rem' }}>({loading.registration_plate})</span>}
                        </Typography>}
                    {loading.driver_phone &&
                        <Typography sx={style}>
                            <LocalPhoneIcon sx={iconStyle}></LocalPhoneIcon>
                            {loading.driver_phone}{loading.driver_name && <>, {loading.driver_name}</>}
                        </Typography>}
                    {loading.notes &&
                        <Typography sx={style}>
                            <NotesIcon sx={iconStyle}></NotesIcon>
                            {loading.notes}
                        </Typography>}
                    {loading.quantity &&
                        <Typography sx={{ ...style, fontWeight: '600' }}>
                            {loading.quantity}
                        </Typography>}
                </Box>
            </Collapse>
            <Popper
                open={buttonOpen}
                anchorEl={buttonRef.current}
                role={undefined}
                transition
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <div>
                            <ClickAwayListener onClickAway={handleClose}>
                                <Card sx={{ width: 180 }}>
                                    <TimeChangeModal loading={loading} close={handleToggle} setLoadings={setLoadings}></TimeChangeModal>
                                </Card>
                            </ClickAwayListener>
                        </div>
                    </Grow>
                )}
            </Popper>
            <Modal
                open={modalOpen}
                onClose={handleModalClose}
            >
                <Box sx={planStyle}>
                    {showImage ?
                        <img src={"data:image/png;base64," + loading.plan} alt="Loading plan" style={{ maxWidth: '90vw', maxHeight: '90vh' }} /> :
                        <iframe src={"data:application/pdf;base64," + loading.plan} title="Loading plan" alt="Loading plan" style={{ maxWidth: '90vw', maxHeight: '90vh', height: "90vh", width: "90vw" }} />
                    }
                </Box>
            </Modal>
        </>);

}