import { AppBar, Button, Badge, Typography, Toolbar, IconButton } from "@mui/material"
import Auth from '../../helpers/Authentication'
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Cargoson } from "./logo-cargoson.svg";
import SettingsIcon from '@mui/icons-material/Settings';
import { useRef, useState } from "react";
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { NavBarDrawer } from "./NavBarDrawer";
import { NavBarSettings } from "./NavBarSettings";

export const NavBar = ({ cable, notificationsCount, resetNotifications, notifications }) => {
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const settingsRef = useRef();
    const { t, i18n } = useTranslation();

    const handleClose = (event) => {
        if (event.target.localName === 'body') {
            return;
        }
        if (settingsRef.current && settingsRef.current.contains(event.target)) {
            return;
        }
        setSettingsOpen(false);
    };

    const handleLogsClose = () => {
        resetNotifications();
        setDrawerOpen((prevOpen) => !prevOpen);
    }

    const handleToggle = () => {
        setSettingsOpen((prevOpen) => !prevOpen);
    };

    const handleLanguage = (event, newLang) => {
        if (newLang !== null) i18n.changeLanguage(newLang);
        localStorage.setItem('locale', newLang);
    }

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const logout = () => {
        if (cable.current) {
            cable.current.disconnect();
            cable.current = null;
        }

        Auth.logout()
        navigate("/sign_in");
        enqueueSnackbar(t('log_out_success'), { variant: "success" })
    }

    return (
        <AppBar position="sticky" color="grey" sx={{ marginBottom: 2 }}>
            <Toolbar>
                <Cargoson style={{ width: '13em' }} />
                <div style={{ flexGrow: 1, justifyContent: 'center', display: 'flex' }}>
                 <Typography variant="h6" >
                    {t('loading_calendar')}
                </Typography>
                </div>
                <div>
                    <IconButton ref={settingsRef} onClick={handleToggle} color="secondary">
                        <SettingsIcon></SettingsIcon>
                    </IconButton>
                    <Badge color="secondary" badgeContent={notificationsCount}>
                        <Button color="primary" onClick={() => setDrawerOpen((prevOpen) => !prevOpen)} variant="contained" >
                            {t('logs')}
                        </Button>
                    </Badge>
                    <Button color="primary" onClick={logout} variant="contained" sx={{ marginLeft: 1 }}>
                        {t('log_out')}
                    </Button>
                </div>
            </Toolbar>

            <NavBarSettings
                open={settingsOpen}
                settingsRef={settingsRef}
                handleClose={handleClose}
                handleLanguage={handleLanguage}></NavBarSettings>
            <NavBarDrawer
                drawerOpen={drawerOpen}
                notifications={notifications}
                notificationsCount={notificationsCount}
                handleLogsClose={handleLogsClose}></NavBarDrawer>
        </AppBar>
    )
}
