import { Grid, Typography, TextField, Button } from "@mui/material"
import axios from "axios";
import Auth from '../helpers/Authentication'
import { ApiUrl } from "../helpers/ApiUrl";
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export const SignIn = () => {
    const initialFormState = { email: '', password: '' }
    const [signInParams, setSignInParams] = useState(initialFormState)
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const handleInputChange = (event) => {
        const { name, value } = event.target
        setSignInParams({ ...signInParams, [name]: value })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        axios.post(ApiUrl() + '/api/v1/get_api_token.json', {...signInParams},
        {
          headers: {
            "Content-Type": "application/json; Charset=UTF-8"
          },
        }).then((resp) => {
            if (resp.status === 200) {
                Auth.login(signInParams.email, resp.data.api_token)
                navigate("/calendar");
                enqueueSnackbar(t('log_in_success'), { variant: "success"})
            }
        })
        .catch(function (error) {
            enqueueSnackbar(t('log_in_failure'), { variant: "error" })
        });
    }


    return (
        <Grid container className="warehouse-background" component="main" alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
            <Grid padding={7} style={{ background: "#FFFFFF", border: "1px solid gray" }}>
                <Typography component="h1" variant="h5">
                    {t('sign_in')}
                </Typography>
                <form noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label={t('email')}
                        name="email"
                        autoComplete="email"
                        onChange={handleInputChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label={t('password')}
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={handleInputChange}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                    >
                        {t('sign_in')}
                    </Button>
                </form>
            </Grid>
        </Grid>
    );
}