export const ApiUrl = () => {
    if (process.env.NODE_ENV === 'development') {
        return 'http://0.0.0.0:3000'
    } else if (window.location.host.includes('staging')) {
        return 'https://cargoson-staging.herokuapp.com'
    } else {
        return 'https://www.cargoson.com'
    }
}

export const WebsocketUrl = () => {
    if (process.env.NODE_ENV === 'development') {
        return 'ws://localhost:3000'
    } else if (window.location.host.includes('staging')) {
        return 'wss://cargoson-staging.herokuapp.com'
    } else {
        return 'wss://www.cargoson.com'
    }
}