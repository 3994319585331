import { TableBody, TableRow, TableCell } from "@mui/material"
import { CalendarCell } from "./CalendarCell";
import format from "date-fns/format";
import { differenceInMinutes, eachMinuteOfInterval, setHours, setMinutes, isWithinInterval, isSameDay, parseISO, addMinutes, addDays } from "date-fns";
import { Fragment, useEffect, useState } from "react";

const getTimeSlot = (givenTime, interval) => {
    return `${format(givenTime, 'HH:mm')} - ${format(addMinutes(givenTime, interval), 'HH:mm')}`
}

const isLoadingSlot = (loading, time, bay, date) => {
    if (bay.id === loading.slot_id &&
        time.getHours() === loading.from_time.getHours() &&
        time.getMinutes() === loading.from_time.getMinutes() &&
        isSameDay(date, parseISO(loading.date))) {
        return true;
    }
    return false;
}

const getIntervalArray = (time, interval) => {
    const start = setMinutes(setHours(new Date(), parseISO(time.start).getUTCHours()), parseISO(time.start).getUTCMinutes());
    const end = setMinutes(setHours(new Date(), parseISO(time.end).getUTCHours()), parseISO(time.end).getUTCMinutes());
    const inter = eachMinuteOfInterval({ start: start, end: end }, { step: interval })
    inter.pop(); // end of day
    return inter;
}

const isWithinSlot = (currentTime, timeSlot, interval) => {
    return isWithinInterval(currentTime, { start: timeSlot, end: addMinutes(timeSlot, interval) })
}

export const CalendarBody = ({ time, interval, loadingBays, loadings, date, setLoadings }) => {
    const usedSlots = loadingBays.map(bay => ({ bay: bay, slotUsed: [], loadings: [] }))
    const timeTable = getIntervalArray(time, interval);
    const [currentTime, setCurrentTime] = useState(Date.now());

    useEffect(() => {
        const interval = setInterval(() => setCurrentTime(Date.now()), 60000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        const eta_ms = addDays(new Date().setHours(0), 1).getTime() - Date.now();
        if (eta_ms > 0) {
            setTimeout(() => {
                window.location.reload();
            }, eta_ms);
        }
    }, []);

    timeTable.forEach((time, timeIndex) => {
        loadingBays.forEach((bay) => {
            const loading = loadings.find(loading => isLoadingSlot(loading, time, bay, date));
            if (loading) {
                if (differenceInMinutes(loading.to_time, loading.from_time) / interval >= 1) {
                    usedSlots.map(obj => {
                        if (obj.bay === bay) {
                            const difference = Math.ceil((differenceInMinutes(loading.to_time, loading.from_time) / interval))
                            const slotsfilled = Array.from(new Array(difference - 1), (x, i) => i + timeIndex + 1)
                            obj.slotUsed = [...obj.slotUsed, ...slotsfilled]
                            obj.loadings = [...obj.loadings, timeIndex]
                        }
                        return obj;
                    })
                }
            }
        })
    })

    return (
        <TableBody>
            {timeTable.map((time, timeIndex) => {
                return (
                    <TableRow key={timeIndex} selected={isWithinSlot(currentTime, time, interval, date)}>
                        <TableCell component="th" sx={{ width: 100, position: 'sticky', left: 0, zIndex: 1, backgroundColor: 'white' }}>{getTimeSlot(time, interval)}</TableCell>
                        {loadingBays.map((bay) => {
                            const slot = usedSlots.find(el => el.bay === bay);
                            return (slot.loadings.includes(timeIndex)) ?
                                (
                                    <CalendarCell
                                        key={bay.id}
                                        time={time}
                                        interval={interval}
                                        timeIndex={timeIndex}
                                        loading={loadings.find(loading => isLoadingSlot(loading, time, bay, date))}
                                        setLoadings={setLoadings}
                                    />
                                ) : (!slot.slotUsed.includes(timeIndex)) ? <TableCell key={bay.id} sx={{ borderLeft: '1px solid #f4f0f0' }} /> : <Fragment key={bay.id}></Fragment>
                        })}
                    </TableRow>)
            })}
        </TableBody>
    )
}