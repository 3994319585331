import axios from "axios";
import { ApiUrl } from "./ApiUrl";

export const getLoadings = (date) => {
    return axios.get(ApiUrl() + '/api/loadingCalendar/loadings?date=' + date,
    {
        headers: {
            "Content-Type": "application/json; Charset=UTF-8",
            'Authorization': `Token ${JSON.parse(localStorage.getItem("api_key")).token}`
        },
    })
};

export const getSlots = () => {
    return axios.get(ApiUrl() + '/api/loadingCalendar/slots',
    {
        headers: {
            "Content-Type": "application/json; Charset=UTF-8",
            'Authorization': `Token ${JSON.parse(localStorage.getItem("api_key")).token}`
        },
    })
};