import { useEffect, useState, useRef } from "react";
import { Table, TableCell, TableHead, TableRow, Typography, CssBaseline, Box } from "@mui/material";
import { NavBar } from "./NavBar/NavBar";
import { CalendarBody } from "./CalendarBody";
import { DatePick } from "./DatePick";
import { set, isSameDay, format, parseISO } from "date-fns";
import { getLoadings, getSlots } from "../helpers/ApiCalls";
import { useSnackbar } from 'notistack';
import Auth from '../helpers/Authentication';
import { createConsumer } from "@rails/actioncable";
import { useTranslation } from 'react-i18next';
import { WebsocketUrl } from "../helpers/ApiUrl";

export const Calendar = () => {
    const [loadingBays, setLoadingBays] = useState([]);
    const [loadings, setLoadings] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [pickedDate, setPickedDate] = useState(new Date());
    const [interval, setInterval] = useState(15);
    const [notificationsCount, setNotificationsCount] = useState(0);
    const [time, setTime] = useState({
        start: 8,
        end: 17
    });

    const baysRef = useRef();
    const cable = useRef();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const handleMessage = (obj) => {
        if (!obj) return;

        let type, message, attribute_value, title;
        const newLoad = {
            ...obj.loading,
            from_time: set(new Date(obj.loading.date), { hours: parseISO(obj.loading.from_time).getUTCHours(), minutes: parseISO(obj.loading.from_time).getMinutes() }),
            to_time: set(new Date(obj.loading.date), { hours: parseISO(obj.loading.to_time).getUTCHours(), minutes: parseISO(obj.loading.to_time).getMinutes() })
        };

        if (obj.message.type === 'delete') {
            setLoadings(prevState =>
                prevState.filter(load => { return load.id !== newLoad.id })
            )
            type = "delete";
            message = 'loading_deleted'
        } else if (!loadings.find(obj => obj.id === newLoad.id)) {
            setLoadings(prevState => {
                return [...prevState, newLoad]
            });
            type = "create";
            message = 'new_loading'
        } else {
            let old_loading = loadings.find(x => x.id === newLoad.id);
            title = `${loadingBays.find(bay => bay.id === old_loading.slot_id).name} - ${format(old_loading.from_time, "HH:mm")}`
            setLoadings((prevState) => {
                return prevState.map(obj => {
                    return (obj.id === newLoad.id) ? newLoad : obj;
                });
            });
            type = "update"
            message = getLogMessage(obj.message);
            attribute_value = obj.message.new_value
        };

        if (!isSameDay(new Date(obj.loading.date), new Date()) || isCurrentUser(obj.user)) return;

        setNotificationsCount(count => count + 1)
        setNotifications(notifs => [{
            title: title ? title : `${loadingBays.find(bay => bay.id === newLoad.slot_id).name} - ${format(newLoad.from_time, "HH:mm")}`,
            message: message,
            attribute_value: attribute_value,
            type: type,
            time: new Date()
        }, ...notifs])
    };

    useEffect(() => {
        if (!cable.current) {
            cable.current = createConsumer(`${WebsocketUrl()}/cable?token=` + Auth.getApiToken())
            const channel = { channel: "LoadingsChannel" };
            const handler = {
                received: (obj) => {
                    baysRef.current(obj)
                },
                disconnected() {
                    cable.current = null;
                }
            }
            cable.current.subscriptions.create(channel, handler)
        }
    }, [loadings])

    useEffect(() => {
        getSlots().then((resp) => {
            if (resp.status === 200) {
                setInterval(Math.min(...resp.data.slots.map(item => item.step_in_minutes)));
                setLoadingBays(resp.data.slots)
                setTime({ start: resp.data.slots[0].open_from, end: resp.data.slots[0].open_until });
                setLoadingBays(oldSlots => {
                    return oldSlots.sort((a, b) => a.name.localeCompare(b.name, 'et-u-kn-true'))
                })
            }
        }).catch((error) => {
            enqueueSnackbar(t('errorLoad', { item: t('slots') }), { variant: "error" })
        })
    }, [])

    useEffect(() => {
        getLoadings(pickedDate.toISOString().substring(0, 10)).then((resp) => {
            if (resp.status === 200) {
                setLoadings(resp.data.loadings.map(load => {
                    return {
                        ...load,
                        from_time: set(new Date(load.date), { hours: parseISO(load.from_time).getUTCHours(), minutes: parseISO(load.from_time).getMinutes() }),
                        to_time: set(new Date(load.date), { hours: parseISO(load.to_time).getUTCHours(), minutes: parseISO(load.to_time).getMinutes() }),
                    }
                }))
            }
        }).catch((error) => {
            enqueueSnackbar(t('errorLoad', { item: t('slots') }), { variant: "error" })
        })
    }, [pickedDate])

    useEffect(() =>
        baysRef.current = handleMessage
    );

    const getLogMessage = (message) => {

        if (message.attribute === 'general') {
            return 'general_change'
        } else if (message.attribute === 'none') {
            return 'no_changes'
        } else if (message.attribute === 'status') {
            return 'status_changed'
        } else if (message.attribute === 'time') {
            return 'time_changed'
        } else if (message.attribute === 'slot') {
            return 'slot_changed'
        }
    }

    const isCurrentUser = (user) => {
        return (user === Auth.getUsername()) ? true : false;
    }

    return (
        <>
            <CssBaseline></CssBaseline>
            <NavBar
                cable={cable}
                notificationsCount={notificationsCount}
                resetNotifications={() => setNotificationsCount(0)}
                notifications={notifications}
            />
            <DatePick pickedDate={pickedDate} setPickedDate={setPickedDate} />
            <Box sx={{ overflow: 'auto', height: 1, maxHeight: 1 }}>
                <Table stickyHeader sx={{ borderCollapse: "separate", borderSpacing: 1 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ minWidth: 120 }}></TableCell>
                            {loadingBays && loadingBays.map((bay, i) => {
                                return (<TableCell key={bay.id} align="center" sx={{ minWidth: 80 }}>
                                            <Typography variant="h6">{bay.name}</Typography>
                                            <Typography sx={{ mb: 1.5 }} color="text.secondary">{bay.description || <br></br>}</Typography>
                                        </TableCell>
                                    )
                            })}
                        </TableRow>
                    </TableHead>
                    <CalendarBody time={time} interval={interval} loadingBays={loadingBays} loadings={loadings} date={pickedDate} setLoadings={setLoadings} />
                </Table>
            </Box>
        </>
    );
}
