import { useState, useEffect } from 'react';
import { Typography } from '@mui/material';

export const Clock = () => {
const [date, setDate] = useState(new Date());

    const refreshClock = () => {
        setDate(new Date());
    } 
    useEffect(() => {
        const timerId = setInterval(refreshClock, 1000);
        return function cleanup() {
            clearInterval(timerId);
        };
    }, []); 

    return (
        <Typography variant='overline' sx={{marginLeft: 2, fontSize: '2em', lineHeight: 'inherit', position: "absolute"}}>
            {date.toLocaleTimeString('et', {hour: '2-digit', minute:'2-digit'})}
        </Typography>
    );
}