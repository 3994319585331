import { createTheme, ThemeProvider } from '@mui/material';
import { Main } from './Main'
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import './i18n/config';


const App = () => {

  const cargosonTheme = createTheme({
    palette: {
      primary: {
        main: '#FFA822'
      },
      secondary: {
        main: '#134E6F'
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: (themeParam) => ({
          "*::-webkit-scrollbar": {
            width: "15px"
          },
          "*::-webkit-scrollbar-track": {
            background: "#E4EFEF"
          },
          "*::-webkit-scrollbar-thumb": {
            background: "#747678",
            borderRadius: "2px"
          },
        }),
      },
    },
  })

  return (
    <ThemeProvider theme={cargosonTheme}>
      <SnackbarProvider>
        <Router>
          <Main></Main>
        </Router>
      </SnackbarProvider>
    </ThemeProvider>

  );
}

export default App;
