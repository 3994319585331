import { useEffect, useState } from "react";
import { CardContent, CardActions, Button, TextField } from "@mui/material";
import { TimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import et from "date-fns/locale/et";
import axios from "axios";
import { ApiUrl } from "../helpers/ApiUrl";
import { format, differenceInMinutes, addMinutes } from "date-fns";
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export const TimeChangeModal = ({ loading, close, setLoadings }) => {
    const [fromValue, setFromValue] = useState(loading.from_time);
    const [toValue, setToValue] = useState(loading.to_time);
    const [difference, setDifference] = useState(differenceInMinutes(toValue, fromValue))
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    useEffect(() => {
        setToValue(oldValue => addMinutes(fromValue, difference))
    }, [fromValue]);

    const handleClick = () => {
        axios.post(ApiUrl() + '/api/loadingCalendar/' + loading.id + '/update_time', { from_time: format(fromValue, 'HH:mm'), to_time: format(toValue, 'HH:mm') },
            {
                headers: {
                    "Content-Type": "application/json; Charset=UTF-8",
                    'Authorization': `Token ${JSON.parse(localStorage.getItem("api_key")).token}`
                },
            }).then((resp) => {
                if (resp.status === 200) {
                    setLoadings(prevState => {
                        const newState = prevState.map(obj => {
                            if (obj.id === loading.id) {
                                return { ...obj, from_time: fromValue, to_time: toValue };
                            }
                            return obj;
                        });
                        enqueueSnackbar(t('change', { item: t('loading_time')}), { variant: 'success' })
                        return newState;
                    });
                    close()
                }
            }).catch((error) => {
                enqueueSnackbar(t('change_fail', { item: t('loading_time')}), { variant: 'error' })
                close()
            })
    }

    return (
        <>
            <CardContent sx={{paddingBottom: 0}}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={et}>
                    <div style={{ paddingBottom: 10 }}>
                        <TimePicker
                            renderInput={(params) => <TextField {...params}/>}
                            label={t('from')}
                            minutesStep={15}
                            value={fromValue}
                            onChange={(newValue) => {
                                setFromValue(newValue);
                            }}
                        />
                    </div>
                    <TimePicker
                        renderInput={(params) => <TextField {...params}/>}
                        label={t('to')}
                        minutesStep={15}
                        value={toValue}
                        onChange={(newValue) => {
                            setToValue(newValue);
                        }}
                    />
                </LocalizationProvider>
            </CardContent>
            <CardActions sx={{justifyContent: "center"}}>
                <Button onClick={handleClick} variant="contained" size="small">{t('time')}</Button>
            </CardActions>
        </>
    )
}