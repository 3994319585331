import { Card, Stack, Avatar, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateIcon from '@mui/icons-material/Update';
import format from "date-fns/format";
import { useTranslation } from 'react-i18next';
import { deepOrange, green, yellow } from '@mui/material/colors';

export const DrawerCard = ({ notification }) => {
    const { t } = useTranslation();

    const getAvatar = () => {
        if (notification.type === "delete") {
            return (<Avatar variant="rounded" sx={{ bgcolor: deepOrange[500], marginRight: 1 }}>
                <DeleteIcon />
            </Avatar>)
        } else if (notification.type === "update") {
            return (<Avatar variant="rounded" sx={{ bgcolor: yellow[500], marginRight: 1 }}>
                <UpdateIcon />
            </Avatar>)
        }
        return (<Avatar variant="rounded" sx={{ bgcolor: green[500], marginRight: 1 }}>
            <AddIcon />
        </Avatar>)
    }

    return (
        <Card sx={{ margin: 1, minWidth: 400, minHeight: 54 }}>
            <Stack direction="row" sx={{ padding: 1, paddingBottom: 0 }}>
                {getAvatar()}
                <div>
                    <Typography variant="h6" sx={{ marginBottom: -1 }}>
                        {notification.title}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                        {notification.message === 'status_changed' ? t(notification.message, {value: t(notification.attribute_value)}) : t(notification.message, {value: notification.attribute_value})}
                    </Typography>
                </div>
                <Typography variant="caption" color="text.secondary" sx={{position: 'absolute', right: 10}}>{format(notification.time, 'HH:mm')}</Typography>
            </Stack>
        </Card>
    )
}