import './App.css';
import { Calendar } from './components/Calendar';
import { SignIn } from './components/SignIn';
import Auth from './helpers/Authentication';
import {Route, Routes, Navigate} from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

export const Main = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  function PrivateRoute({ children }) {
    let loggedIn = Auth.isLoggedIn();
    return loggedIn ? children : <Navigate to="/sign_in"/>;
  }
  useEffect(() => {
    if (document.referrer) {
      enqueueSnackbar(t('redirect'),  { variant: "info" })
    }
  }, []);
  

  return (
    <>
      <Routes>
        <Route exact path="/sign_in" element={<SignIn/>}/>
        <Route exact path="*" element={<SignIn/>}/>
        <Route exact path="/calendar" element={<PrivateRoute><Calendar/></PrivateRoute>}/>
      </Routes>
    </>

  );
}