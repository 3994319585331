import { Popper, Grow, Box, ClickAwayListener, List, ListItem, ListItemIcon, ListItemText, ToggleButtonGroup, ToggleButton } from "@mui/material";

import LanguageIcon from '@mui/icons-material/Language';

import { useTranslation } from 'react-i18next';

export const NavBarSettings = ({open, settingsRef, handleClose, handleLanguage}) => {
    const { t, i18n } = useTranslation();

    return (
    <Popper
        open={open}
        anchorEl={settingsRef.current}
        role={undefined}
        transition
        sx={{ zIndex: 1500 }}
    >
        {({ TransitionProps, placement }) => (
            <Grow
                {...TransitionProps}
                style={{
                    transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom',
                }}
            >
                <Box sx={{ width: 350, bgcolor: '#F5F5F5', borderRadius: 2 }}>
                    <ClickAwayListener onClickAway={handleClose}>
                        <List >
                            <ListItem secondaryAction={
                                <ToggleButtonGroup size="small" onChange={handleLanguage} exclusive value={i18n.language}>
                                    <ToggleButton value="en" aria-label="en">
                                        EN
                                    </ToggleButton>
                                    <ToggleButton value="et" aria-label="et">
                                        ET
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            }>
                                <ListItemIcon>
                                    <LanguageIcon></LanguageIcon>
                                </ListItemIcon>
                                <ListItemText primary={t('language')} />
                            </ListItem>
                        </List>
                    </ClickAwayListener>
                </Box>
            </Grow>
        )}
    </Popper>);
};