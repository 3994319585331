import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { IconButton, TextField, Grid, Button } from '@mui/material';
import { Clock } from "./Clock";
import { addDays } from 'date-fns';
import { enGB, et } from 'date-fns/locale'
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

export const DatePick = ({ pickedDate, setPickedDate }) => {
    const { i18n } = useTranslation();
    const { t } = useTranslation();
    const [language, setLanguage] = useState(enGB);
    const [isOpen, setOpen] = useState(false)

    useEffect(() => {
        if (i18n.language === 'et') {
            setLanguage(et)
        } else {
            setLanguage(enGB)
        }
    }, [i18n.language]);

    function DateIcon(props) {
        return (
            <InsertInvitationIcon {...props} sx={{ color: '#134E6F' }}>

            </InsertInvitationIcon>
        );
    }

    const changeDateToToday = () => {
        setPickedDate(new Date())
        setOpen(false)
    }

    return (
        <Grid container alignItems='center' justifyContent="space-between">
            <Clock />
            <div style={{display: 'flex', marginLeft: "auto", marginRight: "auto"}}>
                <IconButton onClick={() => setPickedDate(addDays(pickedDate, -1))} color="primary">
                    <ArrowBackIosIcon />
                </IconButton>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={language}>
                    <DatePicker
                        open={isOpen}
                        PaperProps={{
                            sx: { paddingBottom: "10px" }
                          }}
                        inputFormat="dd.MM.yyyy"
                        value={pickedDate}
                        onChange={(newValue) => {
                            setPickedDate(newValue)
                            setOpen(false)
                        }}
                        renderInput={(params) => <TextField {...params} onClick={() => setOpen(true)} />}
                        color="primary"
                        components={{
                            OpenPickerIcon: DateIcon,
                            ActionBar: () => (
                                <Button onClick={changeDateToToday} variant="outlined" style={{marginLeft: "auto", marginRight: "auto", display: "block"}}>
                                    {t('today')}
                                </Button>
                                )
                        }}
                    />
                </LocalizationProvider>
                <IconButton onClick={() => setPickedDate(addDays(pickedDate, 1))} color="primary">
                    <ArrowForwardIosIcon />
                </IconButton>
            </div>
        </Grid >
    );
}